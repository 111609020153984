import React, { createContext, useContext, useState, useEffect } from "react";
import { auth, firebaseAuth } from "../utils/firebase";
import { useDb } from "./DbContext";

const AuthContext = createContext(null);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const dbTools = useDb();

  async function signUp(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    dob: string
  ) {
    try {
      await auth.createUserWithEmailAndPassword(email, password);
      await sendVerificationEmail();

      //Creating a "users" Database Entry
      await dbTools.createUser(firstName, lastName, dob, getUserId());

      await logOut();

      return [0, ""];
    } catch (error) {
      return [error.code, error.message];
    }
  }

  function sendVerificationEmail() {
    return auth.currentUser.sendEmailVerification();
  }

  async function sendPasswordResetEmail(email: string) {
    try {
      await auth.sendPasswordResetEmail(email);
      return [0, "Successful"];
    } catch (error) {
      return [error.code, error.message];
    }
  }

  async function changePassword(password: string) {
    try {
      await auth.currentUser.updatePassword(password);
      return null;
    } catch (error) {
      if (error.code === "auth/weak-password") {
        return "Password is too weak.";
      } else if (error.code === "auth/requires-recent-login") {
        return "ReAuthenticate";
      }
    }
  }

  async function changeEmail(email: string) {
    try {
      await auth.currentUser.updateEmail(email);
      return null;
    } catch (error) {
      if (error.code === "auth/invalid-email") {
        return "Invalid Email";
      } else if (error.code === "auth/email-already-in-use") {
        return "Email Already in Use";
      } else if (error.code === "auth/requires-recent-login") {
        return "ReAuthenticate";
      }
    }
  }

  async function ReAuthenticate(email: string, password: string) {
    try {
      const credential = await firebaseAuth.EmailAuthProvider.credential(
        email,
        password
      );
      await auth.currentUser.reauthenticateWithCredential(credential);
      return null;
    } catch (error) {
      return "Wrong Email or Password";
    }
  }

  async function logIn(email: string, password: string) {
    try {
      await auth.signInWithEmailAndPassword(email, password);

      if (auth.currentUser.emailVerified) {
        return [0, ""];
      } else {
        logOut();
        return [1, "Email Not Verified"];
      }
    } catch (error) {
      return [error.code, error.message];
    }
  }

  function logOut() {
    setIsLoggedIn(false);
    return auth.signOut();
  }

  function getUserId() {
    return auth.currentUser.uid;
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);

      user && user.emailVerified ? setIsLoggedIn(true) : setIsLoggedIn(false);

      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const values = {
    currentUser,
    isLoggedIn,
    signUp,
    logIn,
    logOut,
    sendVerificationEmail,
    sendPasswordResetEmail,
    getUserId,
    changePassword,
    changeEmail,
    ReAuthenticate,
  };

  return (
    <AuthContext.Provider value={values}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
