import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import React from "react";

import { useHistory } from "react-router";

import walletOutline from "../images/wallet-outline.svg"

const ReceiptItemDetail: React.FC = () => {
  const history = useHistory();
  var number, pp, startDat, endDat;

  if (history && history.location && history.location.state) {
    const { num, price, startDate, endDate } = history.location.state as any;
    number = num;
    pp = price;
    startDat = startDate;
    endDat = endDate;
  }

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <img src={walletOutline} />
          <IonCardHeader>
            <IonCardTitle>Subscription no. {number}</IonCardTitle>
            <IonCardSubtitle>Details</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <h2>Amount Paid : {pp}€</h2>
            <h2>Valid From : {startDat}</h2>
            <h2>Valid To : {endDat}</h2>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default ReceiptItemDetail;
