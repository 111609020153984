import {
  IonContent,
  IonGrid,
  IonInput,
  IonPage,
  IonRow,
  IonCol,
  IonLabel,
  IonItem,
  IonButton,
  IonAlert,
  IonFooter,
  IonToolbar,
  IonDatetime,
  IonHeader,
  IonTitle,
  IonLoading,
} from "@ionic/react";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router";
import { useAuth } from "../contexts/AuthContext";

const SignUp: React.FC = () => {

  const [showLoading, setShowLoading] = useState(false);

  const emailInputRef = useRef<HTMLIonInputElement>(null);
  const passwordInputRef = useRef<HTMLIonInputElement>(null);
  const rePasswordInputRef = useRef<HTMLIonInputElement>(null);
  const firstNameInputRef = useRef<HTMLIonInputElement>(null);
  const lastNameInputRef = useRef<HTMLIonInputElement>(null);

  const [error, setError] = useState<string>();
  const [isSignUpSuccessful, setSignUpSuccessful] = useState<boolean>(false);
  const [enteredDob, setDob] = useState<string>("1990-06-15");

  
  const authTools = useAuth();
  const history = useHistory();

  async function SignUpHandler() {
    const enteredEmail = emailInputRef.current?.value;
    const enteredPassword = passwordInputRef.current?.value;
    const reEnteredPassword = rePasswordInputRef.current?.value;
    const enteredFirstName = firstNameInputRef.current?.value;
    const enteredLastName = lastNameInputRef.current?.value;

    if (
      !enteredEmail ||
      !enteredPassword ||
      !reEnteredPassword ||
      !enteredFirstName ||
      !enteredLastName
    ) {
      return;
    }

    if (enteredPassword !== reEnteredPassword) {
      setError("Passwords do not match.");
      return;
    }

    //Assuming SignUp is successful
    setSignUpSuccessful(true);

    setShowLoading(true)

    const errors = await authTools.signUp(
      enteredEmail,
      enteredPassword,
      enteredFirstName,
      enteredLastName,
      enteredDob
    );

    setShowLoading(false)

    const errorCode = errors[0],
      errorMessage = errors[1];

    //SignUp Successful
    if (errorCode === 0) {
      //Goto Post Sign up Page
      history.replace("/", { from: "successful sign up" });
    }
    //SignUp Unsuccessful
    else {
      setSignUpSuccessful(false);

      // Handle Errors here.

      if (errorCode === "auth/weak-password") {
        setError("Password is too weak.");
      } else if (errorCode === "auth/email-already-in-use") {
        setError("Email is already registered.");
      } else if (errorCode === "auth/invalid-email") {
        setError("Invalid email.");
      } else {
        setError(errorMessage);
      }
    }
  }

  const clearError = () => {
    setError("");
  };

  return (
    <>
      <IonAlert
        // Double !! Means Convert Variable to Boolean
        isOpen={!!error}
        message={error}
        buttons={[{ text: "Try Again", role: "cancel", handler: clearError }]}
      />

      {/* Loading Component while Loading from API */}
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
      
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle className="ion-text-center">Smart Livestock</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">First name</IonLabel>
                  <IonInput ref={firstNameInputRef} type="text"></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Last name</IonLabel>
                  <IonInput ref={lastNameInputRef} type="text"></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Date of birth</IonLabel>
                  <IonDatetime
                    value={enteredDob}
                    displayFormat="MM/DD/YYYY"
                    onIonChange={(e) => setDob(e.detail.value!.split('T')[0])}
                  ></IonDatetime>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Email</IonLabel>
                  <IonInput ref={emailInputRef} type="email"></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Password</IonLabel>
                  <IonInput ref={passwordInputRef} type="password"></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Confirm your password</IonLabel>
                  <IonInput ref={rePasswordInputRef} type="password"></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton
                  type="submit"
                  disabled={isSignUpSuccessful}
                  onClick={SignUpHandler}
                  expand="block"
                >
                  Sign Up
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        <IonFooter className="ion-text-center">
          <IonToolbar>
            <p>Already have an account?</p>
            <IonButton size="small" fill="clear" href="/">
              {" "}
              Log In.
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    </>
  );
};

export default SignUp;
