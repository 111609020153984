import { Route, Redirect } from "react-router-dom";
import { IonApp } from "@ionic/react";

import { IonReactRouter } from "@ionic/react-router";

import { AuthProvider, useAuth } from "./contexts/AuthContext";

import PrivateRoute from "./utils/PrivateRoute";

import LogIn from "./pages/LogIn";
import SignUp from "./pages/SignUp";
import Dashboard from "./pages/Dashboard";
import PageNotFound from "./components/PageNotFound";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { DbProvider } from "./contexts/DbContext";

const IonicApp: React.FC = () => {
  const authTools = useAuth();

  return (
    <IonApp>
      <IonReactRouter>
        <Route
          path="/"
          exact={true}
          render={() =>
            authTools.isLoggedIn ? <Redirect to="/dashboard" /> : <LogIn />
          }
        />
        <Route
          path="/signup"
          exact={true}
          render={() =>
            authTools.isLoggedIn ? <Redirect to="/dashboard" /> : <SignUp />
          }
        />
        <PrivateRoute path="/dashboard" exact={false} component={Dashboard} />

        <Route component={PageNotFound} />
      </IonReactRouter>
    </IonApp>
  );
};

const App: React.FC = () => {
  return (
    <DbProvider>
      <AuthProvider>
        <IonicApp />
      </AuthProvider>
    </DbProvider>
  );
};

export default App;