import { IonAvatar, IonIcon, IonItem, IonLabel } from "@ionic/react";
import { receiptOutline, chevronForwardOutline } from "ionicons/icons";
import React from "react";
import { useHistory } from "react-router";

const ReceiptItem: React.FC<{
  num: number;
  price: number;
  startDate: Date;
  endDate: Date;
}> = (props) => {
  const history = useHistory();

  function LiveStockItemDetailToggler() {
    history.push(`/dashboard/subscriptions/${props.num}`, {
      num: props.num,
      price: props.price,
      startDate: props.startDate,
      endDate: props.endDate,
    });
  }

  return (
    <IonItem button detail={false} onClick={LiveStockItemDetailToggler}>
      <IonAvatar slot="start">
        <IonIcon icon={receiptOutline} size="large"/>
      </IonAvatar>
      <IonLabel>
        <h2>Subscription no. {props.num}</h2>
        <h3>Valid Till : {props.endDate}</h3>
      </IonLabel>
      <IonAvatar slot="end">
        <IonIcon icon={chevronForwardOutline} />
      </IonAvatar>
    </IonItem>
  );
};

export default ReceiptItem;
