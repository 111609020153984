import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import React from "react";

import { useHistory } from "react-router";

import CardPic from "../images/bg1.jpg";

const LivestockItemDetail: React.FC = () => {
  const history = useHistory();
  var number, temperature, health;

  if (history && history.location && history.location.state) {
    const { num, temp, hel } = history.location.state as any;
    number = num;
    temperature = temp;
    health = hel;
  }

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <IonImg src={CardPic} />
          <IonCardHeader>
            <IonCardTitle>Livestock no. {number}</IonCardTitle>
            <IonCardSubtitle>Stats</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <h2>Temperature : {temperature}°F</h2>
            <h2>Activity : {health}</h2>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default LivestockItemDetail;
