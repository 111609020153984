import {
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonInput,
  IonButton,
  IonPage,
  IonContent,
  IonFooter,
  IonToolbar,
  IonTitle,
  IonAlert,
  IonHeader,
  IonLoading,
} from "@ionic/react";

import React, { useRef, useState } from "react";
import { useStateIfMounted } from "use-state-if-mounted"
import { useHistory } from "react-router-dom";

import { useAuth } from "../contexts/AuthContext";

const LogIn: React.FC = () => {
  const [showLoading, setShowLoading] = useStateIfMounted(false);

  const emailInputRef = useRef<HTMLIonInputElement>(null);
  const passwordInputRef = useRef<HTMLIonInputElement>(null);

  const history = useHistory();

  const [error, setError] = useState<string>();
  var postSignUp = 0;
  const [isForgetPassword, setForgetPassword] = useState<number>(0);

  const postSignUpMessage =
    "You have successfully signed up, we have sent you a verification link on your given email.";

  if (history && history.location && history.location.state && !!!postSignUp) {
    const { from } = history.location.state as any;
    if (from === "successful sign up") {
      postSignUp = 1;
    }
  }

  const authTools = useAuth();

  async function LoginHandler() {
    const enteredEmail = emailInputRef.current?.value;
    const enteredPassword = passwordInputRef.current?.value;

    if (!enteredEmail || !enteredPassword) {
      return;
    }

    setShowLoading(true);

    const errors = await authTools.logIn(enteredEmail, enteredPassword);

    setShowLoading(false);

    const errorCode = errors[0],
      errorMessage = errors[1];

    //login successful
    if (errorCode === 0) {
      //redirect to dashboard
      history.replace("/dashboard");
    }
    //login unsuccessful
    else {
      if (errorCode === 1) {
        setError(
          "Your email is not yet verified, please verify it before signing in."
        );
      } else {
        setError("Incorrect email or password.");
      }
    }
  }

  async function forgetPassword(alertData) {
    setShowLoading(true);

    const errors = await authTools.sendPasswordResetEmail(alertData.Email);

    setShowLoading(false);

    const errorCode = errors[0];

    //Making Post Password Reset Notification Alert Ready
    setForgetPassword(2);

    //Error Came
    if (errorCode === "auth/invalid-email") {
      setError("Invalid email address has been provided, please try again.");
    }

    //Successfully Sent Email (Does not check if a user is registered on the particular email for security reasons)
    else {
      setError(
        "A password reset link has been successfully sent on the given email."
      );
    }
  }

  const clearError = () => {
    setError("");

    //For Post Password Reset Notification
    setForgetPassword(0);
  };

  const clearPostSignUpMessage = () => {
    postSignUp = 0;
    history.replace("/", { from: "" });
  };

  return (
    <>
      {/* Alert for Errors while Logging In or For Displaying Post FOrget Password Notification*/}
      <IonAlert
        isOpen={!!error}
        message={error}
        buttons={[
          {
            text: isForgetPassword === 2 ? "OK" : "Try Again",
            handler: clearError,
          },
        ]}
        backdropDismiss={false}
      />

      {/* Alert for coming from post signUp page */}
      <IonAlert
        isOpen={!!postSignUp}
        message={postSignUpMessage}
        buttons={[
          {
            text: "OK",
            handler: clearPostSignUpMessage,
          },
        ]}
        backdropDismiss={false}
      />

      {/* Input Alert for forget password button press */}
      <IonAlert
        isOpen={isForgetPassword === 1}
        inputs={[
          {
            name: "Email",
            type: "email",
            placeholder: "Enter your email address",
          },
        ]}
        buttons={[
          {
            text: "Submit",
            handler: forgetPassword,
          },
          {
            text: "Cancel",
            handler: () => {
              setForgetPassword(0);
            },
          },
        ]}
        backdropDismiss={false}
      />

      {/* Loading Component while Loading from API */}
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />

      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle className="ion-text-center">Smart Livestock</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Email</IonLabel>
                  <IonInput type="email" ref={emailInputRef}></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Password</IonLabel>
                  <IonInput type="password" ref={passwordInputRef}></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton expand="block" onClick={LoginHandler}>
                  Log in
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-center">
                <IonButton
                  size="small"
                  fill="clear"
                  onClick={() => {
                    setForgetPassword(1);
                  }}
                >
                  Forgot password?
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        <IonFooter className="ion-text-center">
          <IonToolbar>
            <p>Don't have an account?</p>
            <IonButton size="small" fill="clear" href="/signup">
              {" "}
              Sign Up.
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    </>
  );
};

export default LogIn;
