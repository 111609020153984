import {
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useState, useRef, useEffect } from "react";
import ReceiptItem from "../components/ReceiptItem";
import { useAuth } from "../contexts/AuthContext";
import { db } from "../utils/firebase";

function useAsyncReference(value) {
  const ref = useRef(value);
  const [, forceRender] = useState(false);

  function updateState(newState) {
    ref.current = newState;
    forceRender((s) => !s);
  }

  return [ref, updateState];
}

const Subscriptions = () => {
  const [showLoading, setShowLoading] = useState(true);

  const [isFirstTime, setFirstTime] = useState(true);

  const [subscriptionsData, setSubscriptionData] = useAsyncReference([]);

  const authTools = useAuth();

  const dataRef = db.ref("subscriptions/" + authTools.getUserId());

  //no data is present
  useEffect(() => {
    db.ref("subscriptions/")
      .child(authTools.getUserId())
      .once("value", (snapshot) => {
        if (!snapshot.exists()) {
          setFirstTime(false);
          setShowLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    dataRef.on("child_removed", (data) => {
      var dataArr = subscriptionsData.current;

      //Multiple Objects
      if (data.hasChild("price") === false) {
        data.forEach((doc) => {
          dataArr = dataArr.filter((item) => item.key !== doc.key);
        });
      }

      //Single Object
      else {
        dataArr = dataArr.filter((item) => item.key !== data.key);
      }

      setSubscriptionData(dataArr);

      if (isFirstTime) {
        setFirstTime(false);
        setShowLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    dataRef.on("child_changed", (data) => {
      var dataArr = subscriptionsData.current;

      //Multiple Objects
      if (data.hasChild("price") === false) {
        data.forEach((doc) => {
          const index = dataArr.findIndex((element) => element.key === doc.key);
          dataArr[index] = doc;
        });
      }

      //Single Object
      else {
        const index = dataArr.findIndex((element) => element.key === data.key);
        dataArr[index] = data;
      }

      setSubscriptionData(dataArr);

      if (isFirstTime) {
        setFirstTime(false);
        setShowLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    dataRef.on("child_added", (data) => {
      var dataArr = subscriptionsData.current;

      //Multiple Objects
      if (data.hasChild("price") === false) {
        data.forEach((doc) => {
          dataArr.push(doc);
        });
      }

      //Single Object
      else {
        dataArr.push(data);
      }

      setSubscriptionData(dataArr);

      if (isFirstTime) {
        setFirstTime(false);
        setShowLoading(false);
      }
    });
  }, []);

  return (
    <>
      {/* Loading Component while Loading from API */}
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />

      <IonPage>
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle slot="start">Subscriptions</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonList>
            <IonListHeader>
              <IonLabel className="ion-text-center"></IonLabel>
            </IonListHeader>
            {!!subscriptionsData.current &&
            subscriptionsData.current.length > 0 ? (
              subscriptionsData.current
                .sort((a, b) =>
                  a.val().startDate > b.val().startDate ? 1 : -1
                )
                .map((doc, index) => {
                  return (
                    <ReceiptItem
                      key={doc.key}
                      num={index}
                      price={doc.val().price}
                      startDate={doc.val().startDate}
                      endDate={doc.val().endDate}
                    />
                  );
                })
            ) : (
              <>
                <IonList>
                  <IonItem></IonItem>
                  <IonItem className="ion-text-center">
                    <IonText>
                      You do not have any active and past subscriptions.
                    </IonText>
                  </IonItem>
                </IonList>
              </>
            )}
          </IonList>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Subscriptions;
