import {
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import LivestockItem from "../components/LivestockItem";
import { useAuth } from "../contexts/AuthContext";
import { useDb } from "../contexts/DbContext";
import { db } from "../utils/firebase";

function useAsyncReference(value) {
  const ref = useRef(value);
  const [, forceRender] = useState(false);

  function updateState(newState) {
    ref.current = newState;
    forceRender((s) => !s);
  }

  return [ref, updateState];
}

const LivestockItemsList = () => {
  const [showLoading, setShowLoading] = useState(true);
  const [isFirstTime, setFirstTime] = useState(true);

  const [subExpired, setSubExpired] = useState(false);

  const [livestockData, setLivestockData] = useAsyncReference([]);

  const authTools = useAuth();
  const dbTools = useDb();

  const dataRef = db.ref("livestock/" + authTools.getUserId());

  async function isSubValid() {
    const lastSub = await dbTools.getLastSubscriptionAdded(
      authTools.getUserId()
    );

    var lastSubDate = null;
    lastSub.forEach((data) => {
      lastSubDate = data.val().endDate;
    });
    var currentDate = new Date();

    const d1 = Date.parse(currentDate.toDateString());
    const d2 = Date.parse(lastSubDate);

    if (d1 > d2 || lastSubDate === null) {
      setSubExpired(true);
    }
  }

  //no data is present
  useEffect(() => {
    isSubValid();
    db.ref("livestock/")
      .child(authTools.getUserId())
      .once("value", (snapshot) => {
        if (!snapshot.exists()) {
          setFirstTime(false);
          setShowLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    {
      !subExpired &&
        dataRef.on("child_removed", (data) => {
          var dataArr = livestockData.current;

          //Multiple Objects
          if (data.hasChild("activity_status") === false) {
            data.forEach((doc) => {
              dataArr = dataArr.filter((item) => item.key !== doc.key);
            });
          }

          //Single Object
          else {
            dataArr = dataArr.filter((item) => item.key !== data.key);
          }

          setLivestockData(dataArr);

          if (isFirstTime) {
            setFirstTime(false);
            setShowLoading(false);
          }
        });
    }
  }, []);

  useEffect(() => {
    {
      !subExpired &&
        dataRef.on("child_changed", (data) => {
          var dataArr = livestockData.current;

          //Multiple Objects
          if (data.hasChild("activity_status") === false) {
            data.forEach((doc) => {
              const index = dataArr.findIndex(
                (element) => element.key === doc.key
              );
              dataArr[index] = doc;
            });
          }

          //Single Object
          else {
            const index = dataArr.findIndex(
              (element) => element.key === data.key
            );
            dataArr[index] = data;
          }

          setLivestockData(dataArr);

          if (isFirstTime) {
            setFirstTime(false);
            setShowLoading(false);
          }
        });
    }
  }, []);

  useEffect(() => {
    {
      !subExpired &&
        dataRef.on("child_added", (data) => {
          var dataArr = livestockData.current;

          //Multiple Objects
          if (data.hasChild("activity_status") === false) {
            data.forEach((doc) => {
              dataArr.push(doc);
            });
          }

          //Single Object
          else {
            dataArr.push(data);
          }

          setLivestockData(dataArr);

          if (isFirstTime) {
            setFirstTime(false);
            setShowLoading(false);
          }
        });
    }
  }, []);

  return (
    <>
      {/* Loading Component while Loading from API */}
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />

      <IonPage>
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle slot="start">Connected Livestock</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonList>
            <IonListHeader>
              <IonLabel className="ion-text-center"></IonLabel>
            </IonListHeader>
            {!subExpired &&
            !!livestockData.current &&
            livestockData.current.length > 0 ? (
              livestockData.current.map((doc, index) => {
                return (
                  <LivestockItem
                    key={doc.key}
                    num={index}
                    activity={doc.val().activity_status}
                    temperature={doc.val().temperature}
                  />
                );
              })
            ) : (
              <>
                <IonList>
                  <IonItem></IonItem>
                  <IonItem className="ion-text-center">
                    <IonText>
                      {subExpired
                        ? "Please renew your subscription to avail our services."
                        : "Consider subscribing to one of our plans to get realtime statistics of your livestock."}
                    </IonText>
                  </IonItem>
                </IonList>
              </>
            )}
          </IonList>
        </IonContent>
      </IonPage>
    </>
  );
};

export default LivestockItemsList;
