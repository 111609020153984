import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const PrivateRoute = ({component: Component, ...rest}) => {

  const authTools = useAuth()

  return (
      <Route {...rest} render={props => (
          authTools.isLoggedIn ?
              <Component {...props} />
          : <Redirect to="/" />
      )} />
  );
};

export default PrivateRoute;