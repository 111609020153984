import { IonAvatar, IonIcon, IonItem, IonLabel } from "@ionic/react";
import React from "react";

import Logo from "../images/logo2.png";
import { chevronForwardOutline } from "ionicons/icons";
import { useHistory } from "react-router";

const LivestockItem: React.FC<{
  num: number;
  activity: string;
  temperature: number;
}> = (props) => {
  const history = useHistory();

  function LiveStockItemDetailToggler() {
    history.push(`/dashboard/livestock/${props.num}`, {
      num: props.num,
      temp: props.temperature,
      hel: props.activity,
    });
  }

  return (
    <IonItem button detail={false} onClick={LiveStockItemDetailToggler}>
      <IonAvatar slot="start">
        <img src={Logo} />
      </IonAvatar>
      <IonLabel>
        <h2>Livestock no. {props.num}</h2>
        <h3>Activity : {props.activity}</h3>
        <p>Temperature : {props.temperature}°F</p>
      </IonLabel>
      <IonAvatar slot="end">
        <IonIcon icon={chevronForwardOutline} />
      </IonAvatar>
    </IonItem>
  );
};

export default LivestockItem;
