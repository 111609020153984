import React, { createContext, useContext } from "react";
import { db } from "../utils/firebase";

const DbContext = createContext(null);

export function useDb() {
  return useContext(DbContext);
}

export function DbProvider({ children }) {
  async function createUser(
    firstName: string,
    lastName: string,
    dob: string,
    uid: string
  ) {
    try {
      await db.ref("users/" + uid).set({
        firstName: firstName,
        lastName: lastName,
        dob: dob,
      });

      return true;
    } catch (error) {
      return false;
    }
  }

  async function getUser(uid: string) {
    try {
      const data = await db.ref("users/" + uid).once("value");
      return data.val();
    } catch (error) {
      return null;
    }
  }

  async function getLastSubscriptionAdded(uid: string) {
    try {
      const data = await db
        .ref("subscriptions/" + uid)
        .limitToLast(1)
        .once("value");
      return data;
    } catch (error) {
      return null;
    }
  }

  async function changeUserName(
    uid: string,
    firstName: string,
    lastName: string
  ) {
    try {
      await db
        .ref()
        .child("users/" + uid)
        .update({
          firstName: firstName,
          lastName: lastName,
        });

      return null;
    } catch (error) {
      return error.message;
    }
  }

  async function changeDob(uid: string, dob: string) {
    try {
      await db
        .ref()
        .child("users/" + uid)
        .update({
          dob: dob,
        });

      return null;
    } catch (error) {
      return error.message;
    }
  }

  const values = {
    createUser,
    getUser,
    changeUserName,
    changeDob,
    getLastSubscriptionAdded,
  };

  return <DbContext.Provider value={values}>{children}</DbContext.Provider>;
}
