import React from 'react'

const PageNotFound:React.FC = () => {
    return (
        <div>
            PAGE NOT FOUND
        </div>
    )
}

export default PageNotFound
