import {
  IonAlert,
  IonAvatar,
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  calendarOutline,
  keyOutline,
  mailOutline,
  pencilOutline,
  personOutline,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useDb } from "../contexts/DbContext";

const Profile: React.FC = () => {
  const [showLoading, setShowLoading] = useState(false);

  const dbTools = useDb();
  const authTools = useAuth();

  const [userData, setUserData] = useState(null);

  const [isName, setName] = useState<boolean>(false);
  const [isDob, setDob] = useState<boolean>(false);
  const [isEmail, setEmail] = useState<boolean>(false);
  const [isPassword, setPassword] = useState<boolean>(false);

  const [reAuthenticateFor, setReAuthenticateFor] = useState<string>("");
  const [dataBeforeReAuth, setDataBeforeReAuth] = useState<string>("");

  //For re-fetching data from api
  const [isFirstTimeFetch, setFirstTimeFetch] = useState<boolean>(true);
  const [isUserDataChanged, setIsUserDataChanged] = useState<boolean>(false);

  const [error, setError] = useState<string>();
  const [isVerifyCredentials, setVerifyCredentials] = useState<boolean>(false);

  const [requireReLogin, setReLogin] = useState<number>(0);

  async function changeName(alertData) {
    const newFirstName = alertData.firstName;
    const newLastName = alertData.lastName;

    if (
      !newFirstName ||
      !newLastName ||
      newFirstName.trim().length === 0 ||
      newLastName.trim().length === 0
    ) {
      setName(false);
      setError("Please provide valid first and last name.");
      return;
    }

    setShowLoading(true);

    const error = await dbTools.changeUserName(
      authTools.getUserId(),
      newFirstName,
      newLastName
    );

    setShowLoading(false);

    setName(false);

    if (error !== null) {
      setError(error);
    } else {
      setIsUserDataChanged(true);
    }
  }

  async function changeDob(alertData) {
    const newDob = alertData.Dob;

    if (!newDob) {
      setDob(false);
      setError("Please provide a valid dob.");
      return;
    }

    setShowLoading(true);

    const error = await dbTools.changeDob(authTools.getUserId(), newDob);

    setShowLoading(false);

    setDob(false);

    if (error !== null) {
      setError(error);
    } else {
      setIsUserDataChanged(true);
    }
  }

  async function changeEmail(alertData) {
    const newEmail = alertData.Email;

    if (!newEmail || newEmail.trim().length === 0) {
      setEmail(false);
      setError("Please provide a valid email address.");
      return;
    }

    setShowLoading(true);

    const error = await authTools.changeEmail(newEmail);

    setShowLoading(false);

    setEmail(false);

    if (error !== null) {
      if (error === "Invalid Email") {
        setError("Entered email is invalid.");
      } else if (error === "Email Already in Use") {
        setError("This email is already in use.");
      } else if (error === "ReAuthenticate") {
        setReAuthenticateFor("Email");
        setDataBeforeReAuth(newEmail);

        setVerifyCredentials(true);
      }
    } else {
      authTools.sendVerificationEmail();
      setReLogin(1);
    }
  }

  async function changePassword(alertData) {
    const newPassword1 = alertData.Password1;
    const newPassword2 = alertData.Password2;

    if (
      !newPassword1 ||
      !newPassword2 ||
      newPassword1.trim().length === 0 ||
      newPassword2.trim().length === 0
    ) {
      setPassword(false);
      setError("Please provide valid passwords.");
      return;
    }

    if (newPassword1 !== newPassword2) {
      setPassword(false);
      setError("Passwords do not match.");
      return;
    }

    setShowLoading(true);

    const error = await authTools.changePassword(newPassword1);

    setShowLoading(false);

    setPassword(false);

    if (error !== null) {
      if (error === "Password is too weak.") {
        setError(error);
      } else if (error === "ReAuthenticate") {
        setReAuthenticateFor("Password");
        setDataBeforeReAuth(newPassword1);

        setVerifyCredentials(true);
      }
    } else {
      setReLogin(2);
    }
  }

  async function getUserDetails() {
    setShowLoading(true);

    const temp = await dbTools.getUser(authTools.getUserId());

    setShowLoading(false);

    setUserData(temp);
  }

  async function doReAuth(alertData) {
    setShowLoading(true);

    const error = await authTools.ReAuthenticate(
      alertData.Email,
      alertData.Password
    );

    setShowLoading(false);

    setVerifyCredentials(false);

    if (error !== null) {
      setDataBeforeReAuth("");
      setReAuthenticateFor("");

      setError("Invalid email or password");
    } else {
      if (reAuthenticateFor === "Email") {
        changeEmail({ Email: dataBeforeReAuth });
      } else if (reAuthenticateFor === "Password") {
        changePassword({
          Password1: dataBeforeReAuth,
          Password2: dataBeforeReAuth,
        });
      }
    }
  }

  useEffect(() => {
    if (isUserDataChanged || isFirstTimeFetch) {
      getUserDetails();

      if (isUserDataChanged) {
        setIsUserDataChanged(false);
      }
      if (isFirstTimeFetch) {
        setFirstTimeFetch(false);
      }
    }
  }, [isUserDataChanged]);

  return (
    <>
      {/* Error Messages */}
      <IonAlert
        isOpen={!!error}
        message={error}
        buttons={[
          {
            text: "Try Again",
            handler: () => {
              setError("");
            },
          },
        ]}
        backdropDismiss={false}
      />

      {/* Login Redirect */}
      <IonAlert
        header="Logging out"
        isOpen={!!requireReLogin}
        message={
          requireReLogin === 1
            ? "Please verify your new email and login with new credentials."
            : "Please login with your new credentials."
        }
        buttons={[
          {
            text: "OK",
            handler: () => {
              authTools.logOut();
            },
          },
        ]}
        backdropDismiss={false}
      />

      {/* ReAuthenticate */}
      <IonAlert
        header="Please verify your existing credentials"
        isOpen={isVerifyCredentials}
        inputs={[
          {
            name: "Email",
            type: "text",
            placeholder: "Enter your existing email",
          },
          {
            name: "Password",
            type: "password",
            placeholder: "Enter your existing password",
          },
        ]}
        buttons={[
          {
            text: "Ok",
            handler: doReAuth,
          },
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
              setReAuthenticateFor("");
              setDataBeforeReAuth("");
              setVerifyCredentials(false);
            },
          },
        ]}
      />

      {/* Name Change */}
      <IonAlert
        isOpen={isName}
        inputs={[
          {
            name: "firstName",
            type: "text",
            placeholder: "Enter new first name",
          },
          {
            name: "lastName",
            type: "text",
            placeholder: "Enter new last name",
          },
        ]}
        buttons={[
          {
            text: "Change",
            handler: changeName,
          },
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
              setName(false);
            },
          },
        ]}
      />

      {/* DOB Change */}
      <IonAlert
        isOpen={isDob}
        inputs={[
          {
            name: "Dob",
            type: "date",
            placeholder: "Enter new dob",
          },
        ]}
        buttons={[
          {
            text: "Change",
            handler: changeDob,
          },
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
              setDob(false);
            },
          },
        ]}
      />

      {/* Email Change */}
      <IonAlert
        isOpen={isEmail}
        inputs={[
          {
            name: "Email",
            type: "email",
            placeholder: "Enter new email",
          },
        ]}
        buttons={[
          {
            text: "Change",
            handler: changeEmail,
          },
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
              setEmail(false);
            },
          },
        ]}
      />

      {/* Password Change */}
      <IonAlert
        isOpen={isPassword}
        inputs={[
          {
            name: "Password1",
            type: "password",
            placeholder: "Enter new password",
          },
          {
            name: "Password2",
            type: "password",
            placeholder: "Confirm new password",
          },
        ]}
        buttons={[
          {
            text: "Change",
            handler: changePassword,
          },
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
              setPassword(false);
            },
          },
        ]}
      />

      {/* Loading Component while Loading from API */}
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />

      <IonPage>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle slot="start">Profile</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonCard>
            <IonCardContent>
              <IonItem>
                <IonAvatar slot="start">
                  <IonIcon src={personOutline} size="large" />
                </IonAvatar>
                <IonLabel>
                  Name : {userData && userData.firstName}{" "}
                  {userData && userData.lastName}
                </IonLabel>
                <IonButton
                  fill="clear"
                  onClick={() => {
                    setName(true);
                  }}
                >
                  <IonIcon src={pencilOutline} size="small" />
                </IonButton>
              </IonItem>
            </IonCardContent>
            <IonCardContent>
              <IonItem>
                <IonAvatar slot="start">
                  <IonIcon src={calendarOutline} size="large" />
                </IonAvatar>
                <IonLabel>Dob : {userData && userData.dob}</IonLabel>
                <IonButton
                  fill="clear"
                  onClick={() => {
                    setDob(true);
                  }}
                >
                  <IonIcon src={pencilOutline} size="small" />
                </IonButton>
              </IonItem>
            </IonCardContent>
            <IonCardContent>
              <IonItem>
                <IonAvatar slot="start">
                  <IonIcon src={mailOutline} size="large" />
                </IonAvatar>
                <IonLabel>
                  Email : {userData && authTools.currentUser.email}
                </IonLabel>
                <IonButton
                  fill="clear"
                  onClick={() => {
                    setEmail(true);
                  }}
                >
                  <IonIcon src={pencilOutline} size="small" />
                </IonButton>
              </IonItem>
            </IonCardContent>
            <IonCardContent>
              <IonItem>
                <IonAvatar slot="start">
                  <IonIcon src={keyOutline} size="large" />
                </IonAvatar>
                <IonLabel>Password : {userData && "********"}</IonLabel>
                <IonButton
                  fill="clear"
                  onClick={() => {
                    setPassword(true);
                  }}
                >
                  <IonIcon src={pencilOutline} size="small" />
                </IonButton>
              </IonItem>
            </IonCardContent>
          </IonCard>
          <IonRow>
            <IonCol className="ion-text-center">
              <IonButton
                onClick={() => {
                  authTools.logOut();
                }}
              >
                Log out.
              </IonButton>
            </IonCol>
          </IonRow>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Profile;
