import React from "react";

import {
  IonIcon,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonRouterOutlet,
} from "@ionic/react";
import { statsChart, personCircle, cardOutline } from "ionicons/icons";
import { Redirect, Route } from "react-router-dom";

import Livestock from "./LivestockItemsList";
import Profile from "./Profile";
import LivestockItemDetail from "../components/LivestockItemDetail";
import Subscriptions from "./Subscriptions";
import ReceiptItemDetail from "../components/ReceiptItemDetail";

const Dashboard: React.FC = () => {
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Redirect exact path="/dashboard" to="/dashboard/livestock" />
        <Route
          path="/dashboard/livestock"
          render={() => <Livestock />}
          exact={true}
        />
        <Route
          path="/dashboard/subscriptions"
          render={() => <Subscriptions />}
          exact={true}
        />
        <Route
          path="/dashboard/subscriptions/:id"
          render={() => <ReceiptItemDetail />}
          exact={true}
        />
        <Route
          path="/dashboard/profile"
          render={() => <Profile />}
          exact={true}
        />
        <Route
          path="/dashboard/livestock/:id"
          render={() => <LivestockItemDetail />}
          exact={true}
        />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="livestock" href="/dashboard/livestock">
          <IonIcon icon={statsChart} />
        </IonTabButton>
        <IonTabButton tab="subscriptions" href="/dashboard/subscriptions">
          <IonIcon icon={cardOutline} />
        </IonTabButton>
        <IonTabButton tab="profile" href="/dashboard/profile">
          <IonIcon icon={personCircle} />
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default Dashboard;
